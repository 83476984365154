<template>
  <div class="design">
    <div class="banners">
      <img src="@/assets/images/menu/banner3.jpg" alt="" />
    </div>
    <div class="home">
      <div class="industrial">
        <div class="content">
          <div class="home-title anime">
            <h3><span>Creation</span>惠享</h3>
            <p class="sub">工业产品及解决方案</p>
            <span class="el-icon-caret-bottom"></span>
          </div>
          <el-tabs v-model="activeName" class="anime">
            <el-tab-pane label="laaS硬件解决方案" name="1"> </el-tab-pane>
            <el-tab-pane label="PaaS平台级产品" name="2"></el-tab-pane>
            <el-tab-pane label="SaaS应用级产品" name="3"></el-tab-pane>
          </el-tabs>
          <div class="list anime-delay flex_b" v-if="activeName == 1">
            <div
              :class="[
                'item',
                'anime-delay-list',
                'anime-delay-' + (index + 1),
              ]"
              v-for="(item, index) in industrialList"
              :key="item.id"
            >
              <el-image :src="item.icon" fit="cover" lazy></el-image>
              <div class="info">
                <h3>{{ item.title }}</h3>
                <p>{{ item.desc }}</p>
                <div>
                  <span v-for="items in item.label" :key="items">{{
                    items
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list anime-delay flex_b" v-if="activeName == 2">
            <div
              :class="[
                'item',
                'anime-delay-list',
                'anime-delay-' + (index + 1),
              ]"
              v-for="(item, index) in industrialList"
              :key="item.id"
            >
              <el-image :src="item.icon" fit="cover" lazy></el-image>
              <div class="info">
                <h3>{{ item.title }}2</h3>
                <p>{{ item.desc }}</p>
                <div>
                  <span v-for="items in item.label" :key="items">{{
                    items
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list anime-delay flex_b" v-if="activeName == 3">
            <div
              :class="[
                'item',
                'anime-delay-list',
                'anime-delay-' + (index + 1),
              ]"
              v-for="(item, index) in industrialList"
              :key="item.id"
            >
              <el-image :src="item.icon" fit="cover" lazy></el-image>
              <div class="info">
                <h3>{{ item.title }}3</h3>
                <p>{{ item.desc }}</p>
                <div>
                  <span v-for="items in item.label" :key="items">{{
                    items
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$store.commit("anime");
    }, 300);
    this.$parent.finish ? this.$parent.finish() : null;
  },
  data() {
    return {
      activeName: "1",
      industrialList: [
        {
          id: 1,
          icon: require("@/assets/images/home/icon1.png"),
          title: "工业物联网平台H-ITP",
          desc: "为物联网设备提供稳定的蜂窝联网服务",
          label: ["装备制造业", "汽车行业", "新能源行业"],
        },
        {
          id: 2,
          icon: require("@/assets/images/home/icon2.png"),
          title: "工业大数据平台H-BDP",
          desc: "基于云计算的智能化工业大数据平台",
          label: ["机械加工业", "汽车行业", "电子信息行业"],
        },
        {
          id: 3,
          icon: require("@/assets/images/home/icon3.png"),
          title: "工业知识图谱H-IKG",
          desc: "工业知识的管理和应用服务平台",
          label: ["装备制造业", "汽车行业", "电子信息行业"],
        },
        {
          id: 4,
          icon: require("@/assets/images/home/icon4.png"),
          title: "工业设备智能维保平台H-IEM",
          desc: "整合维保资源，提供标准化维保服务",
          label: ["机械加工业", "汽车行业", "装备制造业"],
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.design {
  .banners {
    img {
      width: 100%;
      display: block;
    }
  }
  .industrial {
    padding: 30px 0 100px;
  }
}
</style>
<style lang="scss">
@import "@/assets/scss/home.scss";
</style>