var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"design"},[_vm._m(0),_c('div',{staticClass:"home"},[_c('div',{staticClass:"industrial"},[_c('div',{staticClass:"content"},[_vm._m(1),_c('el-tabs',{staticClass:"anime",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"laaS硬件解决方案","name":"1"}}),_c('el-tab-pane',{attrs:{"label":"PaaS平台级产品","name":"2"}}),_c('el-tab-pane',{attrs:{"label":"SaaS应用级产品","name":"3"}})],1),(_vm.activeName == 1)?_c('div',{staticClass:"list anime-delay flex_b"},_vm._l((_vm.industrialList),function(item,index){return _c('div',{key:item.id,class:[
              'item',
              'anime-delay-list',
              'anime-delay-' + (index + 1),
            ]},[_c('el-image',{attrs:{"src":item.icon,"fit":"cover","lazy":""}}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.desc))]),_c('div',_vm._l((item.label),function(items){return _c('span',{key:items},[_vm._v(_vm._s(items))])}),0)])],1)}),0):_vm._e(),(_vm.activeName == 2)?_c('div',{staticClass:"list anime-delay flex_b"},_vm._l((_vm.industrialList),function(item,index){return _c('div',{key:item.id,class:[
              'item',
              'anime-delay-list',
              'anime-delay-' + (index + 1),
            ]},[_c('el-image',{attrs:{"src":item.icon,"fit":"cover","lazy":""}}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(item.title)+"2")]),_c('p',[_vm._v(_vm._s(item.desc))]),_c('div',_vm._l((item.label),function(items){return _c('span',{key:items},[_vm._v(_vm._s(items))])}),0)])],1)}),0):_vm._e(),(_vm.activeName == 3)?_c('div',{staticClass:"list anime-delay flex_b"},_vm._l((_vm.industrialList),function(item,index){return _c('div',{key:item.id,class:[
              'item',
              'anime-delay-list',
              'anime-delay-' + (index + 1),
            ]},[_c('el-image',{attrs:{"src":item.icon,"fit":"cover","lazy":""}}),_c('div',{staticClass:"info"},[_c('h3',[_vm._v(_vm._s(item.title)+"3")]),_c('p',[_vm._v(_vm._s(item.desc))]),_c('div',_vm._l((item.label),function(items){return _c('span',{key:items},[_vm._v(_vm._s(items))])}),0)])],1)}),0):_vm._e()],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banners"},[_c('img',{attrs:{"src":require("@/assets/images/menu/banner3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-title anime"},[_c('h3',[_c('span',[_vm._v("Creation")]),_vm._v("惠享")]),_c('p',{staticClass:"sub"},[_vm._v("工业产品及解决方案")]),_c('span',{staticClass:"el-icon-caret-bottom"})])
}]

export { render, staticRenderFns }